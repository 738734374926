export const iframeBlacklist = [
    'https://longitudeprize.org/',
    'www.mayoclinic.org',
    'www.canada.ca',
    'who.int',
    'familydoctor.org',
    'https://www.biomerieux.com/',
    'https://www.drugs.com',
    'https://www.canada.ca',
    'https://www.who.int',
    'www.nhs.uk',
    'medlineplus.gov',
    'www2.hse.ie',
    'medlineplus.gov',
    'patient.info',
    'yourgenome.org',
    'www.cancer.org',
    'gi.org',
    'www.avma.org',
    'http://',
    'safetyandquality.gov.au',
    'www.amr.gov.au',
    'www.betterhealth.vic.gov.au',
    'onehealthtrust.org',
    'www.chp.gov.hk',
    'my.clevelandclinic.org',
    'www.cff.org',
    'www.cysticfibrosis.org.uk ',
    'www.espn.com',
    'antibiotic.ecdc.europa.eu',
    'www.everydayhealth.com',
    'health.ec.europa.eu',
    'www.everydayhealth.com',
    'www.bag.admin.ch',
    'www.forbes.com',
    'www.gohealthuc.com',
    'www.patienten-information.de',
    'www.groundup.org.za',
    'www.healthdirect.gov.au',
    'www.healthhub.sg',
    'healthify.nz',
    'www.hse.ie',
    'www.imperial.ac.uk',
    'msfaccess.org',
    'www.cancer.gov',
    'www.ncbi.nlm.nih.gov',
    'https://ncdc.gov.in',
    'www.nhlbi.nih.gov',
    'www.niaid.nih.gov',
    'www.nigms.nih.gov',
    'www.nia.nih.gov',
    'www.nidcd.nih.gov',
    'hivinfo.nih.gov',
    'assets.publishing.service.gov.uk',
    'www.niaid.nih.gov',
    'www.pfizer.com',
    'pharmac.govt.nz',
    'www.plannedparenthood.org',
    'www.pregnancybirthbaby.org.au',
    'www.folkhalsomyndigheten.se',
    'assets.publishing.service.gov.uk',
    'www.health.qld.gov.au',
    'www.sciencefocus.com',
    'www.thebureauinvestigates.com',
    'www.flemingfund.org',
    'www.thehealthy.com',
    'vimeo.com',
    'www.thermofisher.com',
    'www.unicef.org',
    'www.uicc.org',
    'wedocs.unep.org',
    'www.urologyhealth.org',
    '.who.int'
]