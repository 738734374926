import React, { useEffect, useState } from 'react';

const Count = props => {
    const { number, duration } = props.data
    const [count, setCount] = useState("0")

    useEffect(() => {
        let start = 0;
        const end = parseInt(number.substring(0, 3))
        if (start === end) return;

        let timer = setInterval(() => {
            start += 1;
            setCount(String(start) + number.substring(3))
            if (start === end) clearInterval(timer)
        }, (parseInt(duration) / end) * 1000);

    }, [number, duration]);

    return <p >{count}</p>;
}

export default Count;