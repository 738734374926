import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import logo from './../../assets/AMR-Logo-White.png';

const pages = [
  {
    value: 'Home',
    route: '/'
  },
  {
    value: 'Repository',
    route: '/repository'
  },
  {
    value: 'Metadata',
    route: '/metadata'
  },
  {
    value: 'How to use',
    route: '/how-to-use'
  },
  {
    value: 'Join',
    route: '/join'
  },
  {
    value: 'About Us',
    route: 'https://www.amrpatientalliance.org/'
  }
];

const NavBar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ display: 'flex', flexDirection: 'row', background: '#BB2423' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} onClick={() => navigate('/')}>
        <img src={logo} width='150' height='120' style={{ display: 'flex', alignSelf: 'center', marginLeft: '2.5vw' }} />
      </Box>

      <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.value} onClick={handleCloseNavMenu}>
                  <Link to={page.route} style={{ textDecoration: 'none', color: '#BB2423' }} target={page.value === 'About Us' ? '_blank' : '_self'}>
                    <Typography textAlign="center">{page.value}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Link to={page.route} style={{ textDecoration: 'none', color: '#f6f5f5' }} target={page.value === 'About Us' ? '_blank' : '_self'}>
                <Button
                  key={page.value}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.value}
                </Button>
              </Link>

            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
