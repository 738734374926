import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import './info.css';

const HowToUse = () => (
    <div className='how-to-use-wrapper'>
        <div className="how-to-use-container">
            <h1 className="info-texts-mobile-heading" style={{ width: '25vw', paddingLeft: '1.75%', marginBottom: '1.75px' }}>How to Use</h1>
            <h2 className="info-texts-mobile-heading" style={{ width: '25vw', paddingLeft: '1.75%', marginBottom: '1.75px' }}>How do I search the AMR repository?</h2>
            <div className="how-to-use-container icon-container-mobile">
                <TravelExploreIcon sx={{ fontSize: '7.5vh', color: '#C13938' }} />
            </div>
            <p className='info-texts-mobile' style={{ width: '30vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>
                The repository is searchable by different diseases, conditions or pathogens. You can also
                filter the results by different languages. You can use a free search, by entering the terms in
                the search bar, or use filters to get your desired results.
            </p>
            <h3 className="info-texts-mobile-heading" style={{ width: '25vw', paddingLeft: '1.75%', marginBottom: '1.75px' }}>I received too much information. How can I focus my search?</h3>
            <p className='info-texts-mobile' style={{ width: '30vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>○ Replace general terms with more specific ones</p>
            <p className='info-texts-mobile' style={{ width: '30vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>○ Use the appropriate filters to restrict by language, document type, disease area and
                whether it is disease-specific
            </p>
            <h3 className="info-texts-mobile-heading" style={{ width: '25vw', paddingLeft: '1.75%', marginBottom: '1.75px' }}> I received too little information. How can I expand my search?</h3>
            <p className='info-texts-mobile' style={{ width: '30vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>
                ○ Remove specific terms from your search
            </p>
            <p className='info-texts-mobile' style={{ width: '30vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>○ Try using alternative concepts to describe what you are searching for</p>
        </div>
        <div className="how-to-use-container icon-container">
            <TravelExploreIcon sx={{ fontSize: '55vh', color: '#C13938' }} />
        </div>
    </div>
)

export default HowToUse;