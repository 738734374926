import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import { getDocumentsByDiseaseType, getFeaturedCategory } from "../../services/search-service";
import './topic.css';

const Topic = () => {
    const params = useParams();
    const [documents, setDocuments] = useState([]);
    const [topic, setTopic] = useState({});

    useEffect(() => {
        getDocumentsByDiseaseType(params.topic).then(docs => setDocuments(docs)).catch(err => console.log(err))
        getFeaturedCategory().then(category => setTopic(category)).catch(err => console.log(err));
    }, []);

    return (
        <div style={{ margin: '5px 3vw' }}>
            <div className="topic-container">
                <div>
                    <h1 style={{ color: '#C13938' }}>{topic.display_title}</h1>
                    <p className="topic-paragraph">{topic.long_description}</p>
                    <img src={topic.image_src} alt={topic.short_description} className="img-topic" />
                    <p className="image-licence"><i>{topic.image_licence}</i></p>
                    </div>
                <div>
                <h1 style={{ color: '#C13938' }}>Read more</h1>
                {documents.map(doc => (
                        <Link style={{ textDecoration: 'none', color: 'black' }} to={`/${doc.document_id.toString()}`}>
                            <h3 className="topic-links change-text-style-on-hover">○ {doc.name_of_document}</h3>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Topic;