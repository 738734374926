import './info.css';

const Metadata = () => (
    <>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2.5vh'}}>
            <h4 className="metadata-description" >
                Antimicrobial resistance is a known public health threat, endangering the effectiveness of one of the most powerful tools of modern medicine.
            </h4>
            <h4 className="metadata-description">Addressing AMR requires concerted efforts by all stakeholders, and we as patients can take <a style={{ color: 'red', fontWeight: '900' }} href="http://statement.amrpatientalliance.org/" target="_blank">different actions</a> to contribute to the fight against superbugs.</h4>
            <h4 className="metadata-description">In this repository we bring together available resources for many diseases and conditions in several languages, to help you educate and advocate for preserving antibiotics and preventing infections.</h4>
            <h4 className="metadata-description">And, more importantly, to provide information on how we can act for better individual and global health.</h4>
            <h4 className="metadata-description">Act now to save antibiotics for as long as possible, for as many patients as possible!</h4>

        </div>
        <div className="metadata-container" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ color: "#BB2423" }}>
                <h1 className="info-texts-mobile-heading" style={{ width: '31vw', paddingLeft: '1.75%', marginBottom: '1.75px' }}>AMR Patient Alliance</h1>
                <p className="info-texts-mobile" style={{ width: '31vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>
                    Antimicrobial resistance (AMR) is a known health threat that endangers some of the most
                    valuable medicines – antibiotics and other antimicrobials.
                    But, as patients, it’s hard to
                    understand how we can act and what we can do. And, there is still much we CAN do. </p>
                <p className="info-texts-mobile" style={{ width: '31vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>
                    AMR Patient Alliance, lead by IAPO Patient for Patient Safety Observatory, Active
                    Citizenship Network (ACN), ALCER, CancerCare and SepsisAlliance have created a
                    repository of educational and informative materials categorized by different pathogens – that
                    can help find what you can do about particular infectious disease. </p>
                <p className="info-texts-mobile" style={{ width: '31vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>We also categorized
                    the resources by different categories of risk populations, such as immunocompromised and
                    cancer patients, persons with other chronic conditions and life-threatening diseases.
                </p>
                <p className="info-texts-mobile" style={{ width: '31vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>While still most of the resources are in English, we tryied to gather materials in other
                    languages. If you would like to contribute to the repository, and become partner or supporter,
                    please reach us at: amr@iapo-p4psobservatory.org.
                    We hope this helps in your share of the fight against AMR!
                </p>
            </div>
            <div style={{ color: "#BB2423" }}>
                <h2 className="info-texts-mobile-heading" style={{ width: '40vw', paddingLeft: '1.75%', marginBottom: '1.75px' }}>Data Collection:</h2>
                <p className="info-texts-mobile" style={{ width: '40vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0', fontSize: '0.90rem' }}>
                    To create this repository we used online data collection and scoping method to
                    gather information from various sources. For each language, team of two independent
                    researchers searched publicly available sources and compiled the raw database.
                </p>
                <p className="info-texts-mobile" style={{ width: '40vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0', fontSize: '0.90rem' }} >
                    The raw database was checked, cleaned for duplicates and verified to become the final database.
                </p>

                <h2 className="info-texts-mobile-heading" style={{ width: '40vw', paddingLeft: '1.75%', marginBottom: '1.75px' }}>Data Organization:</h2>
                <p className="info-texts-mobile" style={{ width: '40vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0', fontSize: '0.90rem' }}>
                    The verified entries into the database were categorized by different features, such as
                    language, type of resource (e.g. booklet, infographic, video, brochure, website, etc.), as well
                    as by disease area, condition or pathogen.
                </p>
                <p className="info-texts-mobile" style={{ width: '40vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0', fontSize: '0.90rem' }}>
                    For every entry, specific information of author,
                    source link and short description was added.
                </p>
                <h2 className="info-texts-mobile-heading" style={{ width: '40vw', paddingLeft: '1.75%', marginBottom: '1.75px' }}>
                    Limitations:
                </h2>
                <p className="info-texts-mobile" style={{ width: '40vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0', fontSize: '0.90rem' }}>
                    This repository also has some limitations. The primary limitation is its reliance on publicly available data.
                </p>
                <p className="info-texts-mobile" style={{ width: '40vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0', fontSize: '0.90rem' }}>
                    Secondly, the number of languages is limited, and still the majority of resources presented are in English.
                    Yet, this repository is a growing dynamic database.
                </p>
                <p className="info-texts-mobile" style={{ width: '40vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0', fontSize: '0.90rem' }}>
                    If you would like to contribute with your
                    own resources, resources in another language or with suggestions for improvement, please
                    contact us at: <a href="mailto:amr@iapo-p4psobservatory.org">amr@iapo-p4psobservatory.org</a>
                </p>
            </div>
        </div></>
)

export default Metadata;