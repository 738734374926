import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './subheader.css';
import { Link } from "react-router-dom";
import { useState } from 'react';
import { Button } from '@mui/material';

export const Subheader = () => {
  const [toggleMobileClassName, setToggleMobileClassName] = useState(false);

  return (
    <div>
      <div className="subheader-container">
        {/* <div className="subheader-inner-container"> */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h1 className="subheader-heading">
              Antimicrobial resistance explained
            </h1>
            <h1 className="subheader-heading-2">How can patients, caregivers and advocates contribute to the fight?</h1>
            <Link style={{ color: '#ffffff' }} to={'/metadata'}>
              {/* <h2 className="read-more">Read more!</h2> */}
              <Button variant="filled" sx={{color: '#FFF'}} fullWidth><h3>Read more!</h3></Button>
              </Link>       
          </div>
          
        {/* </div> */}
      </div>
    </div >
  )
}