import './App.css';
import Document from './components/document/document';
import NavBar from './components/shared/navbar';
import { Routes, Route } from 'react-router-dom';
import { Footer } from './components/shared/footer';
import { Home } from './pages/home';
import { RepositoryPage } from './pages/repository';
import HowToUse from './components/info/how-to-use';
import Metadata from './components/info/metadata';
import Join from './components/info/join';
import Topic from './components/home/topic';

const App = () => {
  return (
    <div className='app-wrapper' >
      <NavBar />
      <div className='content'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/repository' element={<RepositoryPage />} />
          <Route path='/:id' element={<Document />} />
          <Route path='/topic/:topic' element={<Topic />} />
          <Route path='/how-to-use' element={<HowToUse />} />
          <Route path='/metadata' element={<Metadata />} />
          <Route path='/join' element={<Join />} />
        </Routes>
      </div>
      <footer className='footer'>
        <Footer />
      </footer>
    </div>
  );
}

export default App;