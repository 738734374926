import { Button, FormControl, FormHelperText, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { sessionStorageKeyDocs } from "../../consts/local-storage-key";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import { getDocuments } from "../../services/search-service";
import { DataGrid } from '@mui/x-data-grid';
import { categoriesValues, diseaseAreaTableColumns, diseaseSpecificTableColumns, documentTypesTableColumns, fullRepoTableColumns, languageTableColumns, tableRowsValues } from "../../consts/categories-config";
import { CustomTextField } from "../home/custom-elements";
import './repository.css';

export const Repository = () => {
    const [documents, setDocuments] = useState([]);
    const [fullRepoSelected, setFullRepoSelected] = useState(true);
    const [diseaseSpecificYesSelected, setDiseaseSpecificYesSelected] = useState(true);
    const [filterDiseasesValue, setFilterDiseasesValue] = useState('')
    const [value, setValue] = useState(0);
    const [fullRepoTabValue, setFullRepoTabValue] = useState('fullrepo');
    const [languageTabValue, setLanguageTabValue] = useState('English');
    const [documentTypeTabValue, setDocumentTypeTabValue] = useState('Booklet');
    const [diseaseSpecificTabValueValue, setDiseaseSpecificTabValueValue] = useState('Yes');

    useEffect(() => {
        const docs = sessionStorage.getItem(sessionStorageKeyDocs);

        if (docs) {
            setDocuments(JSON.parse(docs));
        }

        if (!docs) {
            getDocuments().then(doc => {
                sessionStorage.setItem(sessionStorageKeyDocs, JSON.stringify(doc));
                setDocuments(JSON.parse(docs));
            }).catch(x => console.log(x));
        }
    }, []);

    const TabPanel = props => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <span>{children}</span>
                    </Box>
                )}
            </div>
        );
    }

    // TabPanel.propTypes = {
    //     children: PropTypes.node,
    //     index: PropTypes.number.isRequired,
    //     value: PropTypes.number.isRequired,
    // };

    const a11yProps = index => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    });

    const handleChange = (e, newValue) => setValue(newValue);
    // const handleFullRepoChange = (e, newValue) => setFullRepoTabValue('fullrepo');
    const handleLanguageTabChange = (e, newValue) => setLanguageTabValue(newValue);
    const handleDocumentTypeTabChange = (e, newValue) => setDocumentTypeTabValue(newValue);
    const handleDiseaseSpecificTabChange = (e, newValue) => setDiseaseSpecificTabValueValue(newValue);

    return (
        <div className="main-container">
            <Tabs
                TabIndicatorProps={{ style: { backgroundColor: fullRepoSelected ? "#C13938" : "#d4e1eb" } }}
                value={fullRepoTabValue}
                // onChange={handleFullRepoChange}
                textColor="none"
                aria-label="secondary tabs example"
            >
                <Tab key={'fullrepo'} value={'fullrepo'} label="Full Repository" sx={{ color: '#C13938', fontWeight: 'bold' }} onClick={() => setFullRepoSelected(true)} />
            </Tabs>

            <div className="lower-tabs-container">
                <h3 style={{ color: '#C13938', marginTop: '1.5vh' }}>Browse by:</h3>
                <Tabs
                    TabIndicatorProps={{ style: { backgroundColor: !fullRepoSelected ? "#C13938" : "#d4e1eb" } }}
                    value={value}
                    onChange={handleChange}
                    textColor="none"
                    aria-label="secondary tabs example"
                >
                    <Tab {...a11yProps(0)} label="Document Language" sx={{ color: '#C13938', fontWeight: 'bold' }} onClick={() => setFullRepoSelected(false)} />
                    <Tab {...a11yProps(1)} label="Document Type" sx={{ color: '#C13938', fontWeight: 'bold' }} onClick={() => setFullRepoSelected(false)} />
                    <Tab {...a11yProps(2)} label="Disease specific" sx={{ color: '#C13938', fontWeight: 'bold' }} onClick={() => setFullRepoSelected(false)} />
                </Tabs>
            </div>


            {fullRepoSelected && (
                    <div className="full-repo-data-grid-container">
                        <DataGrid
                            sx={{
                                '& .MuiDataGrid-cell:hover': {
                                    backgroundColor: '#C13938',
                                    color: '#f6f5f5',
                                },
                            }}
                            rows={documents.map(obj => {
                                return {
                                    ...obj,
                                    disease_specific: obj.disease_specific ? 'Yes' : 'No'
                                };
                            })}
                            columns={fullRepoTableColumns}
                            getRowId={(row) => row.document_id}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </div>
            )}

            {!fullRepoSelected && (
                <>
                    <TabPanel value={value} index={0}>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                value={languageTabValue}
                                onChange={handleLanguageTabChange}
                                TabIndicatorProps={{ style: { backgroundColor: "#C13938" } }}
                                textColor="none"
                                aria-label="secondary tabs example"
                                component={'span'}
                            >
                                {categoriesValues.languagesValues.map((el, i) => (
                                    <Tab key={i} value={el} label={el} sx={{ color: '#C13938', fontWeight: 'bold' }} />
                                ))}
                            </Tabs>
                        </Box>
                        <div className="lang-data-grid-container">
                            <DataGrid
                                sx={{
                                    '& .MuiDataGrid-cell:hover': {
                                        backgroundColor: '#C13938',
                                        color: '#f6f5f5',
                                    },
                                }}
                                rows={documents.filter(el => el.document_language === languageTabValue)}
                                columns={languageTableColumns}
                                getRowId={(row) => row.document_id}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                sx={{ width: '100%', margin: 'auto' }}
                                value={documentTypeTabValue}
                                onChange={handleDocumentTypeTabChange}
                                TabIndicatorProps={{ style: { backgroundColor: "#C13938" } }}
                                textColor="none"
                                aria-label="scrollable auto tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                                component={'span'}
                            >
                                {categoriesValues.documentTypeValues.map((el, i) => (
                                    <Tab key={i} value={el} label={el} sx={{ color: '#C13938', fontWeight: 'bold' }} />
                                ))}
                            </Tabs>
                        </Box>
                        <div className='doc-type-data-grid-container'>
                            <DataGrid
                                sx={{
                                    '& .MuiDataGrid-cell:hover': {
                                        backgroundColor: '#C13938',
                                        color: '#f6f5f5',
                                    },
                                }}
                                rows={documents.filter(el => el.document_type === documentTypeTabValue)}
                                columns={documentTypesTableColumns}
                                getRowId={(row) => row.document_id}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                value={diseaseSpecificTabValueValue}
                                onChange={handleDiseaseSpecificTabChange}
                                TabIndicatorProps={{ style: { backgroundColor: "#C13938" } }}
                                textColor="none"
                                aria-label="secondary tabs example"
                                component={'span'}
                            >
                                {categoriesValues.diseaseSpecificValues.map((el, i) => (
                                    <Tab key={i} value={el} label={el} sx={{ color: '#C13938', fontWeight: 'bold' }}
                                        onClick={() => {
                                            if(el === 'Yes') { setDiseaseSpecificYesSelected(true)} 
                                            if(el === 'No') { setDiseaseSpecificYesSelected(false); setFilterDiseasesValue('') }}}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        <div className="disease-specific-data-grid-container">
                            {diseaseSpecificYesSelected && (
                                <div>
                                    <FormControl sx={{ minWidth: '10vw' }} size="small">
                                    <CustomTextField
                                        labelid="demo-simple-select-standard-label"
                                        id="select-filter"
                                        className='select-filter'
                                        name="select-filter"
                                        onChange={(e) => setFilterDiseasesValue(e.target.value)}
                                        label={'Filter disease areas'}
                                        select
                                        size="small"
                                        sx={{ marginTop: '1.5vh' }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {categoriesValues.diseaseTypeValues.map(val => (
                                            <MenuItem value={val}>
                                                {val}
                                            </MenuItem>
                                        ))}
                                    </CustomTextField>
                                    {filterDiseasesValue !== '' && <Button  sx={{display: 'flex', color: '#C13938', alignSelf: 'flex-start'}} onClick={() => setFilterDiseasesValue('')}>Clear Filters</Button>}
                                    <FormHelperText><h3 style={{ color: '#C13938', textAlign: 'left' }}>{filterDiseasesValue}</h3></FormHelperText>
                                </FormControl>
                                </div>
                            )}
                            <DataGrid
                                sx={{
                                    '& .MuiDataGrid-cell:hover': {
                                        backgroundColor: '#C13938',
                                        color: '#f6f5f5',
                                    },
                                }}
                                rows={documents.map(obj => {
                                    return {
                                        ...obj,
                                        disease_specific: obj.disease_specific ? 'Yes' : 'No'
                                    };
                                }).filter(el => filterDiseasesValue === '' ?
                                    el.disease_specific === diseaseSpecificTabValueValue
                                    : el.disease_specific === diseaseSpecificTabValueValue && el.disease_area_and_topic === filterDiseasesValue)}
                                columns={diseaseSpecificTableColumns}
                                getRowId={(row) => row.document_id}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                            />
                        </div>
                    </TabPanel>
                </>
            )}
        </div>
    )
}