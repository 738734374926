import Diversity3Icon from '@mui/icons-material/Diversity3';
import './info.css';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Join = () => (
    <div className='how-to-use-wrapper'>
        <div className="join-container">
            <h1 className="info-texts-mobile-heading" info-texts-mobile-heading style={{ width: '40vw', paddingLeft: '1.75%', marginBottom: '1.75px' }}>Join and Contribute to our AMR repository!</h1>
            <div className="how-to-use-container icon-container-mobile">
                <Diversity3Icon sx={{ fontSize: '7.5vh', color: '#C13938' }} />
            </div>
            <p className='info-texts-mobile' style={{ width: '35vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>
                To combat the AMR and superbugs we need to work together and increase awareness of the issue. Our repository focuses on
                providing information and resources to help individuals and healthcare professionals better
                understand the risks and consequences of antimicrobial resistance.
            </p>
            <p className='info-texts-mobile' style={{ width: '35vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>
                By joining and contributing to our repository, you can help raise awareness and support the
                fight against AMR and superbugs. We welcome contributions from
                individuals, healthcare professionals, and organizations who share our vision for a future
                where everyone has access to effective and safe treatments.
            </p>

            <p className='info-texts-mobile' style={{ width: '35vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>
                We are also seeking help from individuals who can provide resources in other languages.
                Joining our repository is easy, you just need to contact us at <a href="mailto: amr@iapo-p4psobservatory.org"> amr@iapo-p4psobservatory.org </a>
            </p>

            <p className='info-texts-mobile' style={{ width: '35vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>
                Together we can make a difference in the fight against antimicrobial resistance.
            </p>
            <p className='info-texts-mobile' style={{ width: '35vw', padding: '0.25% 1.75%', fontWeight: '700', margin: '0' }}>
                Join our repository today and be part of the solution!
            </p>
            <div className='discover-more-btn' style={{width: '35vw'}}>
                <a class='statement-link-btn' style={{ textDecoration: 'none', color: '#fff', margin: '0 2vw 0 0.6vw' }} href={"http://statement.amrpatientalliance.org/"} target='_blank'>
                    <Button sx={{ background: '#BB2423', marginTop: '2vh' }} fullWidth variant='contained'>Read and sign our statement</Button>
                </a>
            </div>
        </div>
        <div className="join-container icon-container">
            <Diversity3Icon sx={{ fontSize: '62.5vh', color: '#C13938' }} />
        </div>
    </div>
)

export default Join;