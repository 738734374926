import { Link } from "react-router-dom"
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';

export const tableRowsValues = {
    nameOfDocument: 'name_of_document',
    documentType: 'document_type',
    documentLanguage: 'document_language',
    diseaseArea: 'disease_area_and_topic',
    diseaseSpecific: 'disease_specific',
    publisherOwner: 'publisher_owner'
}

const categoriesLabels = {
    document: 'Document',
    languages: 'Language',
    documentType: 'Document Type',
    diseaseArea: 'Disease Area',
    diseaseSpecific: 'Disease-specific',
    publisherOwner: 'Publisher'
}

const dataGridClassNames = {
    cellClassName: 'super-app-theme--cell',
    headerClassName: 'super-app-theme--header'
}

const tableColumns = [
    {
        field: tableRowsValues.nameOfDocument,
        headerName: categoriesLabels.document.toUpperCase(),
        width: 400,
        ...dataGridClassNames
    },
    {
        field: tableRowsValues.documentType,
        headerName: categoriesLabels.documentType.toUpperCase(),
        width: 140,
        ...dataGridClassNames
    },
    {
        field: tableRowsValues.diseaseArea,
        headerName: categoriesLabels.diseaseArea.toUpperCase(),
        width: 150,
        ...dataGridClassNames
    },
    {
        field: tableRowsValues.documentLanguage,
        headerName: categoriesLabels.languages.toUpperCase(),
        width: 100,
        ...dataGridClassNames
    },
    {
        field: tableRowsValues.diseaseSpecific,
        headerName: categoriesLabels.diseaseSpecific.toUpperCase(),
        width: 150,
        ...dataGridClassNames
    },
    {
        field: tableRowsValues.publisherOwner,
        headerName: categoriesLabels.publisherOwner.toUpperCase(),
        width: 505,
        ...dataGridClassNames
    },
    {
        field: 'open',
        headerName: 'Open',
        width: 80, renderCell:
            params => (
                <Link style={{ textDecoration: 'none', color: 'gainsboro' }} to={`/${params.row.document_id}`}>
                    <FileOpenOutlinedIcon />
                </Link>
            ),
        ...dataGridClassNames
    }
];

export const fullRepoTableColumns = [...tableColumns];

export const diseaseAreaTableColumns = [
    tableColumns[0],
    tableColumns[2],
    tableColumns[5],
    tableColumns[6]
];

export const languageTableColumns = [
    tableColumns[0],
    tableColumns[3],
    tableColumns[5],
    tableColumns[6]
];

export const documentTypesTableColumns = [
    tableColumns[0],
    tableColumns[1],
    tableColumns[5],
    tableColumns[6]
];

export const diseaseSpecificTableColumns = [
    tableColumns[0],
    tableColumns[4],
    tableColumns[2],
    tableColumns[5],
    tableColumns[6]
];

export const categoriesValues = {
    languagesValues: ['English', 'Italian', 'Russian', 'Spanish'],
    documentTypeValues: [
        'Booklet',
        'Brochure',
        'Infographic',
        'Leaflet',
        'Policy brief',
        'Research article',
        'Toolkit',
        'Video',
        'Webpage'
    ],
    diseaseTypeValues: [
        'Antimicrobial resistance',
        'COVID 19',
        'Cancer Care',
        'Diabetes',
        'Stomach flu',
        'Fungal infections',
        'General Information',
        'MRSA',
        'Sepsis',
        'Tuberculosis',
        'Urinary Tract Infections',
        'Antibiotic guides',
        'Personal Story',
        'Respiratory Tract Infections',
        'HIV/AIDS',
        'Gonorrhoea',
        'Acinetobacter',
        'Candida Auris',
        'Clostridium Difficile',
        'CRE',
        'Campylobacter',
        'Candida Species',
        'ESBL Enterobacteriaceae',
        'VRE',
        'Pseudomonas Aeruginosa',
        'Salmonella',
        'Shigellosis',
        'Streptococcus Pneumoniae',
        'Group A Streptococcal Disease',
        'Group B Streptococcal Disease',
        'Flu',
        'Immunization',
        'Sinus infection',
        'Ear Infections',
        'Skin infections',
        'STDs',
        'Escherichia Coli',
        'Pneumonia',
        'Strep Throat',
        'Staphylococcus infection',
        'Sore Throat',
        'Cystic Fibrosis',
        'Hepatitis A',
        'Hepatitis B',
        'Hepatitis C',
        'Hepatitis D',
        'Hepatitis E',
        'Herpes Simplex Virus',
        'Trichomoniasis',
        'HPV',
        'Molluscum contagiosum',
        'Scabies',
        'Syphilis',
        'Citrobacter koseri',
        'Chancroid',
        'Valley Fever',
        'Aspergillosis',
        'Staphylococcus Aureus',
        'Athlete\'s Foot',
        'Pseudomembranous colitis',
        'Typhoid fever',
        'Hemolytic Uremic Syndrome',
        'Pneumococcal Disease',
        'Scarlet Fever',
        'Impetigo',
        'Necrotizing Fasciitis',
        'Cellulitis',
        'Streptococcal Toxic Shock Syndrome',
        'Rheumatic Fever',
        'Post-Streptococcal Glomerulonephritis',
        'Chlamydia',
        'VRSA'
    ],
    diseaseSpecificValues: ['Yes', 'No']
};

export const dropdownItems = [
    {
        label: categoriesLabels.languages,
        values: categoriesValues.languagesValues,
        formHelperText: 'Filter by language',
        name: tableRowsValues.documentLanguage
    },
    {
        label: categoriesLabels.documentType,
        values: categoriesValues.documentTypeValues,
        formHelperText: 'Filter document types',
        name: tableRowsValues.documentType
    },
    {
        label: categoriesLabels.diseaseArea,
        values: categoriesValues.diseaseTypeValues,
        formHelperText: 'Filter disease areas',
        name: tableRowsValues.diseaseArea
    },
    {
        label: categoriesLabels.diseaseSpecific,
        values: categoriesValues.diseaseSpecificValues,
        formHelperText: 'Disease-specific',
        name: tableRowsValues.diseaseSpecific
    }
]