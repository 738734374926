import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// export const CustomTextField = styled(TextField)({
//     '& label.Mui-focused': {
//         color: '#C13938',
//     },
//     '& .MuiInput-underline:after': {
//         borderBottomColor: '#C13938',
//     },
//     '& .MuiOutlinedInput-root': {
//         '& fieldset': {
//             borderColor: '#C13938',
//         },
//         '&:hover fieldset': {
//             borderColor: '#C13938',
//         },
//         '&.Mui-focused fieldset': {
//             borderColor: '#C13938',
//         },
//     },
// });

export const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#C13938',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#C13938',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#C13938',
      },
      '&:hover fieldset': {
        borderColor: '#C13938',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#C13938',
      },
      '& .MuiInputAdornment-positionEnd': {
        marginRight: 0
      }
    },
  });

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#C13938',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      maxWidth: 'fit-content'
    },
  }));

  export const setTooltipValues = target => (
    <div>
        <b>{target.name_of_document} <br /></b>
        <b>Type: {target.document_type} <br /></b>
        <b>Language: {target.document_language} <br /></b>
        <b>Publisher: {target.publisher_owner !== '' ? target.publisher_owner : '/'}</b>
    </div>
);