export const APIRoutes = {
    BASE: 'https://repository-amrpatientalliance.herokuapp.com', //'http://localhost:5000', 
    Documents: '/documents/',
    ResourcePages: '/resource-pages/',
    DocumentsNames: '/documents-names',
    MostReadArticles: '/get-most-read-articles',
    FeaturedArticles: '/get-featured-articles',
    PageView: '/record-page-view/',
    GetByDiseaseType: '/get-documents-by-disease-type/',
    FeaturedCategory: '/get-featured-category'
}