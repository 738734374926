import React, { useState, useEffect } from "react";
import { getDocuments, getDocumentsByDiseaseType, getFeaturedArticles, getFeaturedCategory, getMostReadArticles } from "../../services/search-service";
import Autocomplete from '@mui/material/Autocomplete';
import { sessionStorageKeyDocs } from '../../consts/local-storage-key';
import { useNavigate } from 'react-router-dom';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { CustomTextField, LightTooltip, setTooltipValues } from './custom-elements';
import { Button, FormControl, FormHelperText, MenuItem } from '@mui/material';
import { dropdownItems } from '../../consts/categories-config';
import './search.css';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Count from "./count";

const Search = () => {
    const [options, setOptions] = useState([]);
    const [featuredCategory, setFeaturedCategory] = useState({});
    const [featuredCategoryArticles, setFeaturedCategoryArticles] = useState([]);
    const [featured, setFeatured] = useState([]);
    const [mostRead, setMostRead] = useState([]);
    const [documentId, setDocumentId] = useState(0);
    const [isFiltered, setIsFiltered] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [menuOpen, setMenuOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const documents = sessionStorage.getItem(sessionStorageKeyDocs);

        if (documents) {
            const docs = JSON.parse(documents);
            setOptions(docs);

            getFeaturedArticles().then(res => {
                const featured = [];
                const featuredIds = res.map(el => el.featured_document_id);

                for (let i = 0; i < docs.length; i++) {
                    if (featuredIds.includes(docs[i].document_id)) {
                        featured.push(docs[i]);
                    }
                }

                setFeatured(featured.length === 0 ? docs.sort((a, b) => 0.5 - Math.random()).slice(0, 10) : featured);
            }).catch(er => console.log(er))
        }

        if (!documents) {
            getDocuments().then(docs => {

                sessionStorage.setItem(sessionStorageKeyDocs, JSON.stringify(docs));
                setOptions(docs);

                getFeaturedArticles().then(res => {
                    const featured = [];
                    const featuredIds = res.map(el => el.featured_document_id);

                    for (let i = 0; i < docs.length; i++) {
                        if (featuredIds.includes(docs[i].document_id)) {
                            featured.push(docs[i]);
                        }
                    }

                    setFeatured(featured.length === 0 ? docs.sort((a, b) => 0.5 - Math.random()).slice(0, 10) : featured);
                }).catch(er => console.log(er))
            }).catch(x => console.log(x));
        }

        getFeaturedCategory().then(category => {
            getDocumentsByDiseaseType(category.category_title).then(docs => {
                setFeaturedCategoryArticles(
                    docs.sort((a, b) => 0.5 - Math.random()).slice(0, 5)
                )
            });

            setFeaturedCategory(category);
        }).catch(er => console.log(er));

        getMostReadArticles().then(mostRead => setMostRead(mostRead)).catch(er => console.log(er));
    }, []);

    const handleSearch = () => {
        if (documentId !== 0) {
            navigate(documentId.toString())
        }
    }

    const handleKeyDownSearch = e => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.stopPropagation();
        }

        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;

        setSelectedFilters({
            ...selectedFilters,
            [name]: value !== '' ? value : undefined
        });

        setIsFiltered(true);
    };


    const filteredOptions = options.filter((option) => {
        return Object.entries(selectedFilters).every(([filterName, filterValue]) => {
            if (filterName === 'diseasespecific') {
                return filterValue === undefined || option[filterName] === filterValue;
            }
            return filterValue === undefined || filterValue === option[filterName];
        });
    });

    return (
        <div className='container'>
            <div className='select-container'>
                {dropdownItems.map((el, i) => (
                    <FormControl sx={{ minWidth: '10vw' }} size="small">
                        <CustomTextField
                            labelid="demo-simple-select-standard-label"
                            id={el.label.replace(' ', '-').toLowerCase()}
                            className='select-filter'
                            name={el.name}
                            onChange={handleChange}
                            label={el.label}
                            select
                            size="small"
                            sx={{marginBottom: '1vh'}}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {el.values.map(val => (
                                <MenuItem value={val === 'Yes' ? true : val === 'No' ? false : val}>
                                    {val}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                        {/* <FormHelperText>{el.formHelperText}</FormHelperText> */}
                    </FormControl>
                ))}
            </div>
            <div className='search-container'>
                <Autocomplete
                    onOpen={() => setMenuOpen(true)}
                    onClose={() => setMenuOpen(false)}
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={isFiltered ? filteredOptions : options}
                    getOptionLabel={option => option.name_of_document || ''}
                    renderOption={(props, option) => (
                        <li
                            {...props} key={option.document_id}
                            value={option.document_id}
                            onClickCapture={e => setDocumentId(e.target.value)}
                        >
                            {option.name_of_document}
                        </li>
                    )}
                    renderInput={(params) => (
                        <div className='search-container'>
                            <CustomTextField
                                variant='outlined'
                                label={'Search'}
                                onKeyDown={handleKeyDownSearch}
                                {...params}
                                InputProps={{
                                    ...params.InputProps, type: 'search', endAdornment: <SearchOutlinedIcon style={{ color: '#C13938', fontSize: '2.5rem', cursor: 'pointer' }} onClick={handleSearch} />
                                }}
                            />
                        </div>
                    )}
                />
            </div>
            <div className='featured-articles-container' style={{ marginTop: menuOpen ? '42vh' : '5vh' }}>
                <div className="featured-container">
                    <h2 style={{ color: '#BB2423', textAlign: 'left', padding: '2.5px 10px' }}>Featured this week</h2>
                    <div className="featured-info-container">
                        <div>
                            <h4 style={{ color: '#BB2423', margin: '0', textAlign: 'left' }}>
                                {featuredCategory.display_title}
                            </h4>
                        </div>
                        <div className="image-container">
                            <img className="image" src={featuredCategory.image_src} title={featuredCategory.image_licence}/>
                        </div>
                        <div>
                            <p className="featured-description" >
                                {featuredCategory.short_description}
                            </p>
                        </div>
                    </div>
                    <div className="featured-inner-container">
                        {featuredCategoryArticles.map(el =>
                            <LightTooltip disableFocusListener title={setTooltipValues(el)}>
                                <h4 onClick={() => navigate(el.document_id.toString())}
                                    className="change-text-style-on-hover featured-category-articles"
                                    >
                                        ○ {el.name_of_document}</h4>
                            </LightTooltip>
                        )}
                        <div className='discover-more-btn'>
                            <Button variant="contained" fullWidth sx={{ background: '#BB2423', border: '1px solid #BB2423' }} onClick={() => navigate(`/topic/${featuredCategory.category_title}`)}>
                                Discover more articles about this topic
                            </Button>
                        </div>
                    </div>

                </div>
                <div className='single-featured-articles-container'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', height: '7.5vh', marginBottom: '4.5vh', padding: '2.5px 20px' }}>
                        <h2 style={{ color: '#BB2423', textAlign: 'left' }}>Most viewed articles</h2>
                        <VisibilityIcon style={{ alignSelf: 'center', fontSize: '2.5rem', color: '#BB2423', marginRight: '-0.5vw' }} />
                    </div>
                    <div style={{padding: '2.5px 10px'}}>
                        {mostRead.map(el =>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '2vw' }}>
                                <LightTooltip disableFocusListener title={setTooltipValues(el)}>
                                    <h4 onClick={() => navigate(el.document_id.toString())}
                                        className="change-text-style-on-hover"
                                        style={{
                                            textAlign: 'left',
                                            color: '#000',
                                            cursor: 'pointer',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            margin: '0',
                                            alignSelf: 'center'
                                        }}>
                                        ○ {el.name_of_document}
                                    </h4>
                                </LightTooltip>
                                <h4 style={{ display: 'flex', alignSelf: 'center', margin: '0', color: '#000' }}><Count data={{ number: !el.page_views ? '0' : el.page_views.toString(), duration: 3.3 }} /></h4>
                            </div>
                        )}
                    </div>
                </div>
                <div className='single-featured-articles-container'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', height: '6.5vh', marginBottom: '7.5vh', padding: '2.5px 10px' }}>
                        <h2 style={{ color: '#BB2423', textAlign: 'left' }}>You might be interested</h2>
                        <BookmarksIcon style={{ alignSelf: 'center', fontSize: '2.5rem', color: '#BB2423', marginRight: '-0.5vw' }} />
                    </div>
                    <div style={{padding: '2.5px 10px'}}>
                        {featured.map(el =>
                            <LightTooltip disableFocusListener title={setTooltipValues(el)}>
                                <h4 onClick={() => navigate(el.document_id.toString())}
                                    className="change-text-style-on-hover"
                                    style={{
                                        textAlign: 'left',
                                        color: '#000',
                                        cursor: 'pointer',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        alignSelf: 'center'
                                    }}>○ {el.name_of_document}</h4>
                            </LightTooltip>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Search;