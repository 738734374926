import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import "./footer.css";
export const Footer = () => (
    <div className="footer-box">
        <div className="footer-container">
            <p style={{ fontWeight: '700', color: '#ffffff' }}>
                AMR Patient Alliance is hosted by: IAPO Patients for Patient Safety Observatory Rue de Chantepoulet 10, 1201 Geneva, Switzerland
                </p>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 style={{ fontWeight: '700', color: '#ffffff' }}>Find us:</h2>
                <a className="footer-link" href="https://www.iapo-p4psobservatory.org" target="_blank"><LanguageIcon sx={{ fontSize: '2.2rem' }} /></a>
                <a className="footer-link" href="mailto:amr-iapo-p4psobservatory.org" target="_blank"><EmailIcon sx={{ fontSize: '2.2rem' }} /></a>
                <a className="footer-link" href="https://twitter.com/AmrPatient" target="_blank"><TwitterIcon sx={{ fontSize: '2.2rem' }} /></a>
                <a className="footer-link" href="https://www.youtube.com/channel/UCqGHagPWs320COAECudKxnw" target="_blank"><YouTubeIcon sx={{ fontSize: '2.2rem' }} /></a>
                <a className="footer-link" href="https://www.linkedin.com/company/amr-patient-alliance/" target="_blank"><LinkedInIcon sx={{ fontSize: '2.2rem' }} /></a>
            </div>
            <small style={{color: '#ffffff', textAlign: 'center', marginTop: '1.55vh' }} >This project is suppored by <a href="https://www.pfizer.com/" target="_blank" style={{color:'#ffffff', fontWeight: '600'}}>Pfizer Inc.</a> </small>
            <small style={{color: '#ffffff', textAlign: 'center', marginTop: '2vh' }} >Copyright 2023 © Developed by <a href="https://distantpoint.org/" target="_blank" style={{color:'#ffffff', fontWeight: '600'}}>Distant Point</a> </small>
        </div>
    </div>
)