import API from "./base-service";
import { APIRoutes } from "../consts/APIRoutes";

export const getDocument = async id => {
    try {
        const result = await API.get(`${APIRoutes.Documents}${id}`,
            { headers: { 'Content-Type': 'application/json' } });

        return result.data
    } catch (error) {
        return null;
    }
}

export const getDocuments = async () => {
    try {
        const result = await API.get(`${APIRoutes.Documents}`,
            { headers: { 'Content-Type': 'application/json' } });

        return result.data
    } catch (error) {
        return null;
    }
}

export const getResourcePages = async id => {
    try {
        const result = await API.get(`${APIRoutes.ResourcePages}${id}`,
            { headers: { 'Content-Type': 'application/json' } });

        return result.data;
    } catch (error) {
        return null;
    }
}

export const getDocumentsNames = async () => {
    try {
        const result = await API.get(APIRoutes.DocumentsNames,
            { headers: { 'Content-Type': 'application/json' } });

        return result.data;
    } catch (err) {
        return null;
    }
}

export const getMostReadArticles = async () => {
    try {
        const result = await API.get(APIRoutes.MostReadArticles,
            { headers: { 'Content-Type': 'application/json' } });

        return result.data;
    } catch (err) {
        return null;
    }
}

export const getFeaturedArticles = async () => {
    try {
        const result = await API.get(APIRoutes.FeaturedArticles,
            { headers: { 'Content-Type': 'application/json' } });

        return result.data;
    } catch (err) {
        return null;
    }
}

export const recordPageView = async (id) => {
    try {
        const response = await API.post(`${APIRoutes.PageView}${id}`);

        return response.status === 200;
    } catch (err) {
        return null;
    }
}

export const getDocumentsByDiseaseType = async (diseaseType) => {
    try {
        const result = await API.get(`${APIRoutes.GetByDiseaseType}${diseaseType}`,
            { headers: { 'Content-Type': 'application/json' } });

        return result.data;
    } catch (error) {
        return null;
    }
}

export const getFeaturedCategory = async () => {
    try {
        const result = await API.get(APIRoutes.FeaturedCategory, 
            { headers: { 'Content-Type': 'application/json' } });

        return result.data;
    } catch (error) {
        return null;
    }
}

// import API from "./base-service";
// import { APIRoutes } from "../consts/APIRoutes";
// import documents from './documents.json';
// import resourcePages from './resourcePages.json';

// export const getDocuments = async () => {
//     try {
//         return documents;
//     } catch (error) {
//         return null;
//     }
// }

// export const getDocument = async id => {
//     try {
//         return documents.find(x => x.document_id === parseInt(id));
//     } catch (error) {
//         return null;
//     }
// }

// // export const getDocument = async id => {
// //     try {
// //         const result = await API.get(`${APIRoutes.Documents}${id}`,
// //             { headers: { 'Content-Type': 'application/json' } });

// //         return result.data
// //     } catch (error) {
// //         return null;
// //     }
// // }

// // export const getDocuments = async () => {
// //     try {
// //         const result = await API.get(`${APIRoutes.Documents}`,
// //             { headers: { 'Content-Type': 'application/json' } });

// //         return result.data
// //     } catch (error) {
// //         return null;
// //     }
// // }

// export const getResourcePages = async id => {
//     try {
//         return resourcePages.filter(x => x.document_id === parseInt(id));
//     } catch (error) {
//         return null;
//     }
// }

// // export const getResourcePages = async id => {
// //     try {
// //         const result = await API.get(`${APIRoutes.ResourcePages}${id}`,
// //             { headers: { 'Content-Type': 'application/json' } });

// //         return result.data;
// //     } catch (error) {
// //         return null;
// //     }
// // }

// export const getDocumentsNames = async () => {
//     try {
//         const result = await API.get(APIRoutes.DocumentsNames,
//             { headers: { 'Content-Type': 'application/json' } });

//         return result.data;
//     } catch (err) {
//         return null;
//     }
// }

// // export const getMostReadArticles = async () => {
// //     try {
// //         const result = await API.get(APIRoutes.MostReadArticles,
// //             { headers: { 'Content-Type': 'application/json' } });

// //         return result.data;
// //     } catch (err) {
// //         return null;
// //     }
// // }

// export const getMostReadArticles = async () => {
//     try {
//         return documents.sort((a, b) => b.page_views - a.page_views).slice(3, 13);
//     } catch (err) {
//         return null;
//     }
// }

// // export const getFeaturedArticles = async () => {
// //     try {
// //         const result = await API.get(APIRoutes.FeaturedArticles,
// //             { headers: { 'Content-Type': 'application/json' } });

// //         return result.data;
// //     } catch (err) {
// //         return null;
// //     }
// // }
// export const getFeaturedArticles = async () => {
//     try {
//         // const result = await API.get(APIRoutes.FeaturedArticles,
//         //     { headers: { 'Content-Type': 'application/json' } });

//         return [...new Set(Array.from({ length: Math.min(10, documents.length) }, () => documents[Math.floor(Math.random() * documents.length)]))];
//     } catch (err) {
//         return null;
//     }
// }
// // [...new Set(Array.from({length: Math.min(numEntries, arr.length)}, () => arr[Math.floor(Math.random() * arr.length)]))];
// // export const recordPageView = async (id) => {
// //     try {
// //         const response = await API.post(`${APIRoutes.PageView}${id}`);

// //         return response.status === 200;
// //     } catch (err) {
// //         return null;
// //     }
// // }

// export const recordPageView = async (id) => {
//     try {
//         console.log('page view')
//     } catch (err) {
//         return null;
//     }
// }

// // export const getDocumentsByDiseaseType = async (diseaseType) => {
// //     try {
// //         const result = await API.get(`${APIRoutes.GetByDiseaseType}${diseaseType}`,
// //             { headers: { 'Content-Type': 'application/json' } });

// //         return result.data;
// //     } catch (error) {
// //         return null;
// //     }
// // }

// export const getDocumentsByDiseaseType = async (diseaseType) => {
//     try {
//         return documents.filter(x => x.disease_area_and_topic === diseaseType);
//     } catch (error) {
//         return null;
//     }
// }

// // export const getFeaturedCategory = async () => {
// //     try {
// //         const result = await API.get(APIRoutes.FeaturedCategory, 
// //             { headers: { 'Content-Type': 'application/json' } });

// //         return result.data;
// //     } catch (error) {
// //         return null;
// //     }
// // }

// export const getFeaturedCategory = async () => {
//     try {
//         return {
//             featured_categories_id: 19,
//             category_title: "Pneumococcal Disease",
//             display_title: "Pneumococcal disease",
//             short_description: "Pneumococcal disease is a bacterial infection that can cause pneumonia, meningitis, and other severe illnesses, particularly in young children and older adults",
//             long_description: "Pneumococcal disease is an infection caused by the bacteria Streptococcus pneumoniae, which can lead to a range of illnesses including pneumonia, meningitis, and sepsis. It is most commonly spread through respiratory droplets and can be prevented with vaccination. Treatment involves antibiotics and supportive care.",
//             image_src: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Pneumococcus_CDC_PHIL_ID1003.jpg",
//             image_licence: "Photo Credit:Content Providers(s): CDC/Dr. M.S. Mitchell, Public domain, via Wikimedia Commons",
//             has_featured: true
//         }
//     } catch (error) {
//         return null;
//     }
// }