import { React, useState, useEffect } from "react";
import { getDocument, getResourcePages, recordPageView } from "../../services/search-service";
import { Link, useParams } from "react-router-dom";
import YouTube from "react-youtube";
import { sessionStorageKeyDocs } from "../../consts/local-storage-key";
import { LightTooltip, setTooltipValues } from "../home/custom-elements";
import { Button } from "@mui/material";
import { iframeBlacklist } from "../../consts/iframeBlacklist";
import error from './../../../src/assets/error.png';
import './document.css';

const Document = () => {
    const params = useParams();
    const [document, setDocument] = useState({});
    const [resourcePages, setResourcePages] = useState([]);
    const [relatedArticles, setRelatedArticles] = useState([]);
    const [youtubeVideoId, setYoutubeVideoId] = useState('');
    const [isYoutube, setIsYoutube] = useState(false);
    const [isBlacklisted, setIsBlacklisted] = useState(false);
    const documents = sessionStorage.getItem(sessionStorageKeyDocs);

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsYoutube(false);
        recordPageView(params.id).then(() => { }).catch(er => console.log(er));

        getDocument(params.id).then(document => {
            setDocument(document);

            if (document.document_link.includes('youtube')) {
                setIsYoutube(true);
                setYoutubeVideoId(document.document_link.split("v=")[1].split("&")[0]);
            }

            setIsBlacklisted(iframeBlacklist.some(el => document.document_link.includes(el)));

            const filtered = JSON.parse(documents).filter(x => x.disease_area_and_topic === document.disease_area_and_topic);
            const related = filtered.length <= 10 ? filtered : filtered.sort((a, b) => 0.5 - Math.random()).slice(0, 10);
            setRelatedArticles(related);
        }).catch(err => console.log(err));

        getResourcePages(parseInt(params.id)).then(res => setResourcePages(res)).catch(er => console.log(er));
    }, [params]);

    return (
        <>
            <h1 style={{ color: '#C13938', textAlign: 'center' }}>{document.name_of_document}</h1>
            <div className="document-container">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p className="synopsis-paragraph">
                        {document.synopsis}
                    </p>
                    {!isBlacklisted && (
                        <div style={{ display: 'flex' }} >
                            {!isYoutube && <iframe src={document.document_link} title="Source" className="external-source" /*style={{ height: `${window.screen.height - (window.screen.height / 2.5)}`, width: window.screen.width - (window.screen.width / 2) }} */ />}
                            {isYoutube && <YouTube videoId={youtubeVideoId} opts={{ height: `${window.screen.height - (window.screen.height / 2.5)}`, width: window.screen.width - (window.screen.width / 2) }} />}
                        </div>
                    )}
                    {isBlacklisted && (
                        <div className="is-blacklisted">
                           <img className="error-img" src={error} alt="Error Message"/>
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <tr>
                            <td className="td-style">Publisher:</td>
                            <td className="td-style">{document.publisher_owner}</td>
                        </tr>
                        <tr>
                            <td className="td-style">Language:</td>
                            <td className="td-style">{document.document_language}</td>
                        </tr>
                        <tr>
                            <td className="td-style">Type:</td>
                            <td className="td-style">{document.document_type}</td>
                        </tr>
                        <tr>
                            <td className="td-style">Disease area:</td>
                            <td className="td-style">{document.disease_area_and_topic}</td>
                        </tr>
                        <tr>
                            <td className="td-style">Disease specific:</td>
                            <td className="td-style">{document.disease_specific ? 'Yes' : 'No'}</td>
                        </tr>
                    </table>
                    <Button variant="outlined" color='error'>
                        <a style={{ color: '#C13938', textAlign: 'left', fontWeight: '900' }} href={document.document_link} target="_blank">Open external link</a>
                    </Button>
                    <div>
                        {resourcePages.length > 0 && <h3 style={{ color: '#C13938', textAlign: 'left' }}>Resources</h3>}
                        {resourcePages.length > 0 && (
                            resourcePages.map(el => (

                                <a style={{ color: 'black' }} href={el.resource} target="_blank">
                                    <h3 className="document-links change-text-style-on-hover document">{el.resource}</h3>
                                </a>
                            ))
                        )}
                        <h3 style={{ color: '#C13938', textAlign: 'left' }}>Related Articles</h3>
                        {relatedArticles.map(el => (
                            <LightTooltip disableFocusListener title={setTooltipValues(el)}>
                                <Link style={{ textDecoration: 'none', color: 'black' }} to={`/${el.document_id.toString()}`}>
                                    <h3 className="document-links change-text-style-on-hover">○ {el.name_of_document}</h3>
                                </Link>
                            </LightTooltip>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Document;